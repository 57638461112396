import { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAccessToken, hasAccessTokenExpired, resetTokens } from "./";

type ApplyInterceptorsOptions = {
  ignoreIfIncludes: string;
  refreshToken(): Promise<void>;
};

export const applyInterceptors = (
  axios: AxiosInstance,
  options: ApplyInterceptorsOptions
) => {
  const { ignoreIfIncludes, refreshToken } = options;
  axios.interceptors.request.use(
    async (config): Promise<AxiosRequestConfig> => {
      if (config.url && !config.url.includes(ignoreIfIncludes)) {
        try {
          if (await hasAccessTokenExpired()) {
            await refreshToken();
          }

          const token = await getAccessToken();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (err) {
          resetTokens();
          console.error(err);
        }
      }
      return config;
    },
    async (error): Promise<AxiosRequestConfig> => {
      if (error.status !== 401 && error.status !== 403) {
        return Promise.reject(error);
      }

      await refreshToken();

      const token = getAccessToken();
      error.response.config.headers.Authorization = `Bearer ${token}`;

      return axios(error.response.config);
    }
  );
};
