import { useCallback, useEffect, useState } from "react";

export enum AsyncStatus {
  pending = "pending",
  success = "success",
  error = "error",
  idle = "idle",
}

type ArgumentsType<T> = T extends (...args: infer U) => any ? U : never;

export interface AsyncFunction {
  (params?: any): Promise<any>;
}

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export const useAsync = <T extends AsyncFunction>(
  asyncFn: T,
  immediate = false,
  args?: ArgumentsType<T>
) => {
  const [status, setStatus] = useState(AsyncStatus.idle);
  const [data, setData] = useState<AsyncReturnType<T>>();
  const [error, setError] = useState<any>();
  const [isPending, setIsPending] = useState(false);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    async (args?: ArgumentsType<T>) => {
      setStatus(AsyncStatus.pending);
      setIsPending(true);

      setError(undefined);

      try {
        const response = await asyncFn(args);
        setData(response);
        setStatus(AsyncStatus.success);
      } catch (err) {
        setError(err);
        setStatus(AsyncStatus.error);
      } finally {
        setIsPending(false);
      }
    },
    [asyncFn]
  );

  useEffect(() => {
    if (immediate) {
      execute(args);
    }
  }, [immediate]);

  return { execute, status, isPending, data, error };
};
