import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useCallback, useEffect } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  View,
} from "react-native";
import { Button, Card, Headline, TextInput } from "react-native-paper";
import { PublicNavigatorParams } from "../../navigation";
import { useOverlayBloc } from "../../providers/overlay/overlay.bloc";
import { useDimensions } from "../../rockts";
import { Column, Spacer } from "../../rockts/ui-primitives";
import { useFieldValidator } from "../../rockts/use-dto-validator";
import { Client } from "../../services/client";
import { UpdatePasswordDto } from "../../services/dto/update-password.dto";
import { DARK_GRAY, WHITE } from "../../styles/colors";
import s from "./styles";

//FIXME: Issue with linting on react-native-paper components
// https://github.com/callstack/react-native-paper/issues/2482

type NavigationProps = StackNavigationProp<
  PublicNavigatorParams,
  "UpdatePassword"
>;
type RouteProps = RouteProp<PublicNavigatorParams, "UpdatePassword">;
type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

export const UpdatePasswordScreen: React.FC<Props> = (props) => {
  const [fields, updateField] = useFieldValidator(new UpdatePasswordDto());

  const { setShowLoading, notify, notifyError } = useOverlayBloc();
  const dimensions = useDimensions("window");
  const isLargeScreen = dimensions.width > 768;
  const linkTo = useLinkTo();

  const token = props.route.params.token;

  const checkToken = useCallback(async () => {
    await Client.checkPasswordResetToken(token);
  }, [token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const handleSubmit = async () => {
    Keyboard.dismiss();
    setTimeout(async () => {
      // Dismiss keybord before calling sign in
      await handleUpdatePassword();
    }, 200);
  };

  const handleUpdatePassword = async () => {
    try {
      setShowLoading(true);
      const { password, confirmPassword } = fields;
      if (password !== confirmPassword) {
        throw Error("Password confirmation must match.");
      }
      await Client.updatePassword(token, { password });
      notify("Your password has been set sucessfully!");
      linkTo("/login");
    } catch (err) {
      notifyError(err);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS == "ios" ? "padding" : "height"}
      style={{ flex: 1, backgroundColor: WHITE, alignItems: "center" }}
    >
      <Column
        flex={1}
        width={isLargeScreen ? 400 : "100%"}
        justifyContent="center"
      >
        <Image
          resizeMode="contain"
          style={s.logo}
          source={require("../../assets/logo.png")}
        />

        <Card style={s.card}>
          <Card.Content>
            <Headline style={s.headline}>{"Set\n Password"}</Headline>
            <Spacer />
            <TextInput
              style={s.textInput}
              theme={{ colors: { primary: DARK_GRAY } }}
              mode="outlined"
              value={fields.password}
              returnKeyType="done"
              label="Password"
              autoCorrect={false}
              autoCapitalize="none"
              secureTextEntry
              onChangeText={(text) => updateField("password", text)}
            />
            <Spacer />
            <TextInput
              style={s.textInput}
              theme={{ colors: { primary: DARK_GRAY } }}
              mode="outlined"
              value={fields.confirmPassword}
              returnKeyType="done"
              label="Confirm Password"
              autoCorrect={false}
              autoCapitalize="none"
              onSubmitEditing={handleSubmit}
              onChangeText={(text) => updateField("confirmPassword", text)}
              secureTextEntry
            />
            <Spacer />
            <Button
              contentStyle={s.button}
              labelStyle={s.buttonLabel}
              mode="contained"
              onPress={handleSubmit}
            >
              Submit
            </Button>
            <Spacer space={20} />
            <Button
              labelStyle={s.forgotPassLabel}
              onPress={() => {
                linkTo("/login");
              }}
            >
              Cancel
            </Button>
            <Spacer space={10} />
          </Card.Content>
        </Card>
      </Column>
      <View style={s.background} />
      <Spacer />
      <Image
        style={s.background}
        source={require("../../assets/background.png")}
      />
    </KeyboardAvoidingView>
  );
};
