import { Subject } from "rxjs";

export type SubjectsType = {
  [key: string]: Subject<any>;
};

function createName(name: string) {
  return `$ ${name}`;
}

export class Emitter {
  constructor(private subjects: SubjectsType = {}) {}
  emit(name: string, data: any) {
    var fnName = createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new Subject());
    this.subjects[fnName].next(data);
  }
  listen(name: string, handler: () => void) {
    var fnName = createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new Subject());
    return this.subjects[fnName].subscribe(handler);
  }
  dispose() {
    var subjects = this.subjects;
    for (var prop in subjects) {
      subjects[prop].unsubscribe();
    }
  }
}
