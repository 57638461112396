import { MaterialIcons } from "@expo/vector-icons";
import { DrawerActions } from "@react-navigation/native";
import { StackHeaderProps } from "@react-navigation/stack";
import React, { Fragment } from "react";
import { Platform } from "react-native";
import { Appbar, useTheme } from "react-native-paper";

export const Header = ({ scene, previous, navigation }: StackHeaderProps) => {
  const { options } = scene.descriptor;
  const { colors } = useTheme();

  const title =
    options.headerTitle !== undefined
      ? options.headerTitle
      : options.title !== undefined
      ? options.title
      : scene.route.name;

  const statusBarHeight = Platform.OS === "ios" ? 50 : 30;

  return (
    <Fragment>
      <Appbar.Header statusBarHeight={statusBarHeight}>
        {previous ? (
          <Appbar.Action
            animated={false}
            icon={({ color, size }) => (
              <MaterialIcons color={color} size={size} name="arrow-back" />
            )}
            onPress={() => navigation.goBack()}
          />
        ) : (
          <Appbar.Action
            animated={false}
            icon={({ color, size }) => (
              <MaterialIcons color={color} size={size} name="menu" />
            )}
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
          />
        )}
        <Appbar.Content title={title} />

        {/* {!previous && (
          <Appbar.Action
            animated={false}
            icon={({ color, size }) => (
              <MaterialIcons color={color} size={size} name="search" />
            )}
            onPress={() => linkTo("/handbook/search")}
          />
        )} */}
      </Appbar.Header>
    </Fragment>
  );
};
