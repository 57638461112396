import React, { useEffect, useState } from "react";
import { Linking, Platform, View } from "react-native";
import {
  ActivityIndicator,
  Card,
  Headline,
  Subheading,
  ThemeProvider,
} from "react-native-paper";
import { WebView } from "react-native-webview";
import { CompanyAvatar } from "../components/atoms/CompanyAvatar";
import { Wrapper } from "../components/atoms/Wrapper";
import InquirySection from "../components/molecules/InquirySection";
import { getCompanyVideo } from "../helpers";
import { useStateProvider } from "../providers/state.provider";
import { Column, Container, Row, Spacer } from "../rockts/ui-primitives";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { wp } from "../styles/mixins";
import { standardTheme } from "../styles/themes";

export const HomeScreen = () => {
  const { company, inquiryTypes } = useStateProvider();

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (company) {
      setVideoUrl(getCompanyVideo(company));
    }
  }, [company]);

  if (!company)
    return (
      <Container flex={1} center>
        <ActivityIndicator size={50} animating={true} />
      </Container>
    );

  return (
    <Wrapper>
      <MaxWidthView>
        <ThemeProvider theme={standardTheme}>
          <Spacer space={20} />
          <Column alignItems="center" justifyContent="center" width="100%">
            <Card style={{ width: "90%" }}>
              <Spacer space={40} />
              <View style={{ alignSelf: "center" }}>
                <CompanyAvatar company={company} />
              </View>
              <Spacer space={40} />
              <Headline style={{ textAlign: "center" }}>
                {company.name}
              </Headline>
              <Spacer />
              <Card.Content>
                <Row justifyContent="center">
                  <Subheading style={{ textAlign: "center", width: "80%" }}>
                    {company.welcomeText}
                  </Subheading>
                </Row>
                <Spacer />
                {videoUrl ? (
                  <Row width="100%" justifyContent="center" alignItems="center">
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      {Platform.OS !== "web" ? (
                        <Row height={200}>
                          <WebView
                            scrollEnabled={false}
                            allowsInlineMediaPlayback={true}
                            injectedJavaScript={`
                            window.addEventListener('contextmenu', function (e) {              
                              e.preventDefault();
                            }, false);`}
                            source={{
                              uri: videoUrl,
                            }}
                            onShouldStartLoadWithRequest={(event) => {
                              if (event.url !== videoUrl) {
                                Linking.openURL(event.url);
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          />
                        </Row>
                      ) : (
                        <iframe
                          width="560"
                          height="315"
                          src={videoUrl}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      )}
                    </View>
                  </Row>
                ) : (
                  <View />
                )}
                <Spacer space={40} />
                <InquirySection inquiries={inquiryTypes} />
              </Card.Content>
            </Card>
            <Spacer space={wp(5)} />
          </Column>
        </ThemeProvider>
      </MaxWidthView>
    </Wrapper>
  );
};
