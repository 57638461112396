import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

type DimensionType = "window" | "screen";

export const useDimensions = (type: DimensionType) => {
  const [dimensions, setDimensions] = useState(Dimensions.get(type));

  useEffect(() => {
    const currentDimensions = Dimensions.get(type);
    setDimensions(currentDimensions);
  }, [type]);

  useEffect(() => {
    function dimensionsChange(params: any) {
      setDimensions(params[type]);
    }

    Dimensions.addEventListener("change", dimensionsChange);
    return () => {
      Dimensions.removeEventListener("change", dimensionsChange);
    };
  }, [type]);

  return dimensions;
};
