import React, { useEffect, useRef, useState } from "react";
import { Animated, Platform, StyleSheet } from "react-native";
import { ActivityIndicator, Portal } from "react-native-paper";
import { VIEWPORT_HEIGHT, VIEWPORT_WIDTH } from "../../../styles/mixins";

const isWeb = Platform.OS === "web";

type Props = {
  visible: boolean;
};

const duration = 150;

export const LoadingOverlay = ({ visible }: Props) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      fadeOut();
      setTimeout(() => setIsVisible(false), duration);
    } else {
      setIsVisible(true);
      fadeIn();
    }
  }, [visible]);

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: duration / 2,
      useNativeDriver: isWeb ? false : true,
    }).start();
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: isWeb ? false : true,
    }).start();
  };
  if (!isVisible) {
    return null;
  }
  return (
    <Portal>
      <Animated.View style={[styles.wrapper, { opacity: fadeAnim }]}>
        <ActivityIndicator size={70} animating={isVisible} />
      </Animated.View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "rgba(255,255,255,0.7)",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    zIndex: 9999,
    left: 0,
    top: 0,
    height: VIEWPORT_HEIGHT,
    width: VIEWPORT_WIDTH,
  },
});
