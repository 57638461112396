import { MaterialIcons } from "@expo/vector-icons";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Row, Spacer } from "../rockts";
import { ForgotPasswordScreen } from "../screens/public/forgot-password-screen";
import { LoginScreen } from "../screens/public/login-screen";
import { UpdatePasswordScreen } from "../screens/public/update-password-screen";

export type PublicNavigatorParams = {
  Login: undefined;
  ForgotPassword: undefined;
  UpdatePassword: { token: string };
};

const Stack = createStackNavigator();

const PublicNavigator = () => (
  <Stack.Navigator
    screenOptions={{
      headerBackTitleVisible: false,
      headerBackImage: () => (
        <Row>
          <Spacer space={10} />
          <MaterialIcons color={"black"} size={26} name="arrow-back" />
        </Row>
      ),
    }}
  >
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen
      name="ForgotPassword"
      options={{ title: "Forgot Password" }}
      component={ForgotPasswordScreen}
    />
    <Stack.Screen
      name="UpdatePassword"
      options={{ title: "Set Password" }}
      component={UpdatePasswordScreen}
    />
  </Stack.Navigator>
);

export default PublicNavigator;
