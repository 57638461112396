import { validate, ValidationError } from "class-validator";
import { useCallback, useState } from "react";

const cleanFromValidator = <T>(dto: T, errors: ValidationError[]) => {
  const dtoKeys = Object.keys(dto) as KeysOf<T>[];

  const msgs = {} as StringObjectWithKeysOf<T>;
  dtoKeys.forEach((key) => {
    const fieldError = errors.find((error) => {
      return error.property === key;
    });
    if (!fieldError) {
      return;
    }
    // Get Constraints
    const { constraints } = fieldError;
    // Get first validation message
    if (!constraints) return;
    msgs[key] = constraints[Object.keys(constraints)[0]];
  });
  return msgs;
};

export const useFieldValidator = <T>(DTO: T): UseValidatorReturnType<T> => {
  const [dto, setDto] = useState<T>(DTO);
  const [fields, setFields] = useState<FieldsOf<T>>({ ...dto });
  const [msgs, setMsgs] = useState<StringObjectWithKeysOf<T>>();

  const updateField: UpdateFieldFunction<T> = (key, value) => {
    // if fields are empty create it
    const newFields = fields ?? ({} as FieldsOf<T>);

    dto[key] = value;
    newFields[key] = value;

    // Need to spread to update state
    setFields({ ...newFields });
    setDto(dto);
  };

  const validDto = useCallback(async () => {
    const results = await validate(dto);
    const isValid = results.length === 0;

    // If valid clear messages and return
    if (isValid) {
      setMsgs(undefined);
      return dto;
    }

    const validationMsgs = cleanFromValidator<T>(dto, results);
    setMsgs(validationMsgs);
    return false;
  }, [dto]);

  return [fields, updateField, msgs, validDto];
};
