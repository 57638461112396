import { StyleSheet } from "react-native";
import { ACCENT, BLACK, WHITE } from "../../styles/colors";

export const styles = StyleSheet.create({
  card: {
    elevation: 5,
    marginLeft: 20,
    marginRight: 20,
  },
  logo: {
    width: 150,
    height: 150,
    alignSelf: "center",
  },
  button: {
    height: 60,
    backgroundColor: ACCENT,
  },
  buttonLabel: {
    color: BLACK,
    fontWeight: "bold",
    fontSize: 20,
  },
  forgotPassLabel: {
    color: BLACK,
    fontSize: 16,
    textTransform: "none",
  },
  textInput: {
    backgroundColor: WHITE,
  },
  background: {
    zIndex: -1,
    height: "100%",
    width: "100%",
    position: "absolute",
    resizeMode: "cover",
  },
  headline: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  formWrap: {
    justifyContent: "center",
  },
});

export default styles;
