import { useLinkTo } from "@react-navigation/native";
import React from "react";
import { Image, Keyboard, KeyboardAvoidingView, Platform } from "react-native";
import { Button, Card, Headline, TextInput } from "react-native-paper";
import { useLoading, useNotify } from "../../providers/overlay/overlay.bloc";
import { useDimensions } from "../../rockts";
import { Column, Spacer } from "../../rockts/ui-primitives";
import { useFieldValidator } from "../../rockts/use-dto-validator";
import { Client } from "../../services/client";
import { ForgotPasswordDto } from "../../services/dto/forgot-password.dto";
import { DARK_GRAY, WHITE } from "../../styles/colors";
import s from "./styles";

export const ForgotPasswordScreen = () => {
  const [fields, updateField] = useFieldValidator(new ForgotPasswordDto());

  const notify = useNotify();
  const showLoading = useLoading();

  const dimensions = useDimensions("window");
  const linkTo = useLinkTo();

  const handleSubmit = async () => {
    Keyboard.dismiss();
    setTimeout(async () => {
      // Dismiss keybord before calling sign in
      await handleForgotPassword();
    }, 200);
  };

  const handleForgotPassword = async () => {
    try {
      showLoading(true);
      const { email } = fields;
      await Client.resetPassword(email);
      notify("Forgot password request sent sucessfully.");
      linkTo("/login");
    } catch (err) {
      showLoading(false);
    } finally {
      showLoading(false);
    }
  };

  const isLargeScreen = dimensions.width > 768;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS == "ios" ? "padding" : "height"}
      style={{ flex: 1, backgroundColor: WHITE, alignItems: "center" }}
    >
      <Column
        flex={1}
        margin={20}
        width={isLargeScreen ? 400 : "100%"}
        justifyContent="center"
      >
        <Image
          resizeMode="contain"
          style={s.logo}
          source={require("../../assets/logo.png")}
        />
        <Card style={s.card}>
          <Card.Content>
            <Spacer space={60} />
            <Headline style={s.headline}>{"Forgot\n Password"}</Headline>
            <Spacer />
            <TextInput
              //TODO: Abstract TextInput and its styling
              style={s.textInput}
              theme={{ colors: { primary: DARK_GRAY } }}
              value={fields.email}
              mode="outlined"
              autoCorrect={false}
              autoCapitalize="none"
              onChangeText={(text) => updateField("email", text)}
              label="Email"
            />
            <Spacer />

            <Spacer space={70} />
            <Button
              contentStyle={s.button}
              labelStyle={s.buttonLabel}
              mode="contained"
              onPress={handleSubmit}
            >
              Submit
            </Button>
            <Spacer />
          </Card.Content>
        </Card>
      </Column>
      <Spacer />
      <Image
        style={s.background}
        source={require("../../assets/background.png")}
      />
    </KeyboardAvoidingView>
  );
};
