import React, { FunctionComponent } from "react";
import { ViewStyle } from "react-native";
import { Container } from "./Container";

interface Props extends ViewStyle {}

export const Column: FunctionComponent<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <Container flexDirection="column" {...rest}>
      {children}
    </Container>
  );
};
