import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { getCompanyLogo } from "../../helpers";
import { Company } from "../../services/sdk";
import { WHITE } from "../../styles/colors";
import { shadow as shadowStyle } from "../../styles/shadow";

type Props = {
  company?: Company;
  shadow?: boolean;
};

export const CompanyAvatar: React.FC<Props> = ({ company, shadow = false }) => {
  if (!company) return <View />;

  return (
    <Image
      style={[styles.avatar, shadow && { ...shadowStyle }]}
      source={{
        uri: getCompanyLogo(company),
      }}
    />
  );
};

const styles = StyleSheet.create({
  avatar: {
    resizeMode: "contain",
    backgroundColor: WHITE,
    width: 300,
    height: 100,
  },
});
