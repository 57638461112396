import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { Fragment, useState } from "react";
import { Linking, StyleSheet, TextInput, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Button, ThemeProvider } from "react-native-paper";
import HTML from "react-native-render-html";
import { Wrapper } from "../components/atoms/Wrapper";
import { PrivateNavigatorParams } from "../navigation";
import {
  useLoading,
  useNotify,
  useNotifyError,
} from "../providers/overlay/overlay.bloc";
import { useStateProvider } from "../providers/state.provider";
import { Column, Container, Row, Spacer } from "../rockts/ui-primitives";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { Client } from "../services/client";
import { standardTheme } from "../styles/themes";

type NavigationProps = StackNavigationProp<PrivateNavigatorParams, "Inquiry">;
type RouteProps = RouteProp<PrivateNavigatorParams, "Inquiry">;
type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

export const InquiryScreen: React.FC<Props> = ({ navigation, route }) => {
  const { inquiryTypes } = useStateProvider();

  const [content, setContent] = useState("");
  const showLoading = useLoading();
  const linkTo = useLinkTo();
  const notifyError = useNotifyError();
  const notify = useNotify();

  const { inquiryTypeId } = route.params;

  // get inquiry from context
  const inquiryType = inquiryTypes?.find(
    (inquiryType) => inquiryType.id == inquiryTypeId
  );

  const submitMessage = async () => {
    try {
      showLoading(true);
      await Client.sendInquiry({ inquiryTypeId, content });
      notify("Inquiry submitted sucessfully.");
      navigation.goBack();
    } catch (err) {
      notifyError(err);
    } finally {
      showLoading(false);
    }
  };

  return (
    <Fragment>
      <ThemeProvider theme={standardTheme}>
        <Wrapper>
          <MaxWidthView>
            <KeyboardAwareScrollView>
              <Spacer space={40} />

              <Column paddingHorizontal={20}>
                <HTML
                  baseFontStyle={{ fontSize: 16 }}
                  source={{ html: inquiryType?.content ?? "" }}
                  onLinkPress={(_: any, url: string) => {
                    Linking.openURL(url);
                  }}
                />
              </Column>

              <Spacer />
              <Row paddingHorizontal={20}>
                <View style={styles.textAreaContainer}>
                  <TextInput
                    style={styles.textArea}
                    underlineColorAndroid="transparent"
                    placeholder="Type something"
                    placeholderTextColor="grey"
                    numberOfLines={10}
                    multiline={true}
                    onChangeText={setContent}
                  />
                </View>
              </Row>
              <Spacer />
              <Container flex={1} />
              <Container paddingHorizontal={20}>
                <Button
                  labelStyle={{ padding: 10 }}
                  mode="contained"
                  onPress={submitMessage}
                >
                  Submit
                </Button>
              </Container>
            </KeyboardAwareScrollView>
          </MaxWidthView>
        </Wrapper>
      </ThemeProvider>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  textAreaContainer: {
    borderColor: "#ccc",
    borderWidth: 1,
    padding: 5,
    flex: 1,
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
  },
});
