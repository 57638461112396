import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import React, { FunctionComponent } from "react";
import { Linking, TextStyle } from "react-native";
import { Button, Caption, useTheme } from "react-native-paper";
import { useLoading } from "../../providers/overlay/overlay.bloc";
import { getVersionNumber } from "../../rockts/helpers/appInfo";
import { Column, Container, Spacer } from "../../rockts/ui-primitives";
import { Client } from "../../services/client";

export const DrawerMenu: FunctionComponent<DrawerContentComponentProps> = ({
  navigation,
}) => {
  const showLoading = useLoading();
  const theme = useTheme();

  const onLogout = async () => {
    showLoading(true);
    navigation.dispatch(DrawerActions.closeDrawer());
    setTimeout(async () => {
      try {
        await Client.logout();
      } finally {
        showLoading(false);
      }
    }, 1000);
  };

  const colors = theme.colors;

  const buttonProps = {
    color: colors.text,
    labelStyle: { fontSize: 16 } as TextStyle,
  };

  return (
    <DrawerContentScrollView
      contentContainerStyle={{
        backgroundColor: colors.surface,
        flex: 1,
      }}
    >
      <Column flex={1} alignItems="flex-start">
        <Spacer space={60} />
        <Button
          {...buttonProps}
          onPress={() => navigation.navigate("Home", { screen: "Home" })}
        >
          Home
        </Button>
        <Spacer />

        <Button
          {...buttonProps}
          onPress={async () => {
            await Linking.openURL("mailto:support@inhandbook.com");
          }}
        >
          Support
        </Button>
        <Spacer />
        <Button {...buttonProps} onPress={onLogout}>
          Logout
        </Button>
        {/* <Spacer />
        <Button
          {...buttonProps}
          onPress={() => navigation.navigate("Debugger")}
        >
          Debugger
        </Button> */}
        <Spacer />
        <Container flexGrow={1} />

        <Caption
          style={{
            marginBottom: 40,
            marginLeft: 10,
          }}
        >{getVersionNumber()}</Caption>
      </Column>
      {/* </ThemeProvider> */}
    </DrawerContentScrollView>
  );
};
