import React from "react";
import { View } from "react-native";

type Props = {
  space?: number;
};

export const Spacer = ({ space = 20 }: Props) => {
  return <View style={{ height: space, width: space }} />;
};
