import React, { FunctionComponent } from "react";
import { AuthProvider } from "../services/auth/auth.provider";
import { Client } from "../services/client";
import { NotificationProvider } from "../services/notification/notification.provider";
import { OverlayProvider } from "./overlay/overlay.bloc";
import { StateProvider } from "./state.provider";

const ModulesContainer: FunctionComponent<{}> = (props) => {
  const { children } = props;
  return (
    <NotificationProvider>
      <OverlayProvider>
        <AuthProvider initialState={{ fetchUser: Client.getUser }}>
          <StateProvider>{children}</StateProvider>
        </AuthProvider>
      </OverlayProvider>
    </NotificationProvider>
  );
};

export default ModulesContainer;
