import { useLinkTo } from "@react-navigation/native";
import React, { Fragment, useState } from "react";
import { RefreshControl, View } from "react-native";
import { Headline } from "react-native-paper";
import { Divider } from "../components/atoms/Divider";
import ListItem from "../components/atoms/ListItem";
import { Wrapper } from "../components/atoms/Wrapper";
import { useStateProvider } from "../providers/state.provider";
import { Spacer } from "../rockts/ui-primitives";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { Section } from "../services/sdk";

export const HandBookScreen: React.FC<{}> = () => {
  const { handbookSections, refreshState } = useStateProvider();

  const [refreshing, setRefreshing] = useState(false);
  const linkTo = useLinkTo();

  const onRefresh = async () => {
    setRefreshing(true);
    await refreshState();
    setRefreshing(false);
  };

  const SectionList = ({ sections }: { sections?: Section[] }) => {
    if (!sections) return <View />;
    return (
      <Fragment>
        {sections.map((section) => {
          const { title, id } = section;
          return (
            <ListItem
              key={id}
              title={title || ""}
              onPress={() => linkTo(`/handbook/section/${id}`)}
            />
          );
        })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Wrapper
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <MaxWidthView>
          <Spacer space={40} />
          <Headline style={{ textAlign: "center" }}>Sections</Headline>
          <Spacer />
          <Divider />
          <Spacer />
          <SectionList sections={handbookSections} />
        </MaxWidthView>
      </Wrapper>
    </Fragment>
  );
};
