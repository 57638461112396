import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { getCompanyTheme } from "../helpers";
import { useAuthProvider } from "../services/auth/auth.provider";
import { Client } from "../services/client";
import { Company, Handbook, InquiryType, Section, User } from "../services/sdk";

const useHooks = () => {
  const { authUser } = useAuthProvider();
  const user = authUser as User;
  const [company, setCompany] = useState<Company>();
  const [inquiryTypes, setInquiryTypes] = useState<InquiryType[]>();
  const [handbook, setHandbook] = useState<Handbook>();
  const [handbookSections, setHandbookSections] = useState<Section[]>();
  const [companyTheme, setCompanyTheme] = useState<ReactNativePaper.Theme>();
  const [isLoading, setIsLoading] = useState(false);

  const loadState = useCallback(async () => {
    if (!user) throw Error("Could not load app state without user.");
    if (!user.companyId) throw Error("Could not load company of user");

    setIsLoading(true);

    try {
      const userCompany = await Client.getCompany(user.companyId);
      setCompany(userCompany);

      // Sets the company theme based on configuration
      const theme = getCompanyTheme(userCompany);
      setCompanyTheme(theme);

      const companyInquiryTypes = await Client.getInquiryTypes(user.companyId);
      setInquiryTypes(companyInquiryTypes);

      const companyHandbook = await Client.getHandbook(user.companyId);
      setHandbook(companyHandbook);

      if (companyHandbook?.id) {
        const handbookSections = await Client.getSections(companyHandbook.id);
        setHandbookSections(handbookSections);
      }
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const resetState = () => {
    setCompany(undefined);
    setInquiryTypes(undefined);
    setHandbook(undefined);
    setHandbookSections(undefined);
  };

  useEffect(() => {
    if (user) loadState();
    else resetState();
  }, [user]);

  return {
    user,
    company,
    isReady: !!company,
    companyTheme,
    isLoading,
    inquiryTypes,
    handbook,
    handbookSections,
    refreshState: loadState,
  };
};

const State = createContainer(useHooks);

export const StateProvider = State.Provider;
export const useStateProvider = State.useContainer;
