import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import { Emitter } from "./events";
import { JwtToken, Tokens } from "./types";

const ACCESS_TOKEN = "@accessToken";
const REFRESH_TOKEN = "@refreshToken";

enum AuthEvents {
  SetTokens = "auth:service:save:tokens",
  ResetTokens = "auth:service:clear:tokens",
}

const AuthServiceEvents = new Emitter();

export const isAuthenticated = async () => {
  return (await getAccessToken()) !== null;
};

export const getAccessToken = async (): Promise<string | null> => {
  return await AsyncStorage.getItem(ACCESS_TOKEN);
};

export const getRefreshToken = async (): Promise<string | null> => {
  return await AsyncStorage.getItem(REFRESH_TOKEN);
};

export const setTokens = async ({ accessToken, refreshToken }: Tokens) => {
  await AsyncStorage.setItem(ACCESS_TOKEN, accessToken);
  await AsyncStorage.setItem(REFRESH_TOKEN, refreshToken);
  AuthServiceEvents.emit(AuthEvents.SetTokens, accessToken);
};

export const resetTokens = async () => {
  await AsyncStorage.removeItem(ACCESS_TOKEN);
  await AsyncStorage.removeItem(REFRESH_TOKEN);
  AuthServiceEvents.emit(AuthEvents.ResetTokens, null);
};

export const getClaims = async (): Promise<JwtToken | null> => {
  const accessToken = await getAccessToken();
  if (!accessToken) return null;
  // Decode accessToken to get exp
  return await jwtDecode(accessToken);
};

export const signOut = async (): Promise<void> => {
  await resetTokens();
};

export const hasAccessTokenExpired = async () => {
  const claims = await getClaims();
  return hasExpired(claims?.exp);
};

function hasExpired(exp?: number, buffer = 30) {
  if (!exp) return true;
  const secondsNow = Date.now() / 1000;
  return secondsNow >= exp - buffer;
}

export function onResetTokens(fn: () => void) {
  return AuthServiceEvents.listen(AuthEvents.ResetTokens, fn);
}

export function onSetTokens(fn: () => void) {
  return AuthServiceEvents.listen(AuthEvents.SetTokens, fn);
}
