import Constants from "expo-constants";
import { ENV } from "../../env.json";

/// Returns version number together with build number
export const getVersionNumber = () => {
  const version = Constants.manifest.version;
  const buildNumber = Constants.nativeBuildVersion;
  return `v${version} (${buildNumber ?? ""}${getEnvironment()})`;
};

/// Get running environment
const getEnvironment = () => {
  if (ENV === "production") {
    return "p";
  }

  if (ENV === "staging") {
    return " next";
  }

  if (ENV === "development") {
    return " dev";
  }
  return ENV;
};
