import * as Notifications from "expo-notifications";
import { useEffect, useRef, useState } from "react";
import { createContainer } from "unstated-next";
import { registerForPushNotificationsAsync } from "../notification";

function useNotificationHooks() {
  const [expoPushToken, setExpoPushToken] = useState<string>();
  const [notification, setNotification] = useState<
    Notifications.Notification
  >();
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  useEffect(() => {
    registerForPushNotificationsAsync()
      .then((token) => setExpoPushToken(token))
      .catch((err) => {
        setExpoPushToken(JSON.stringify(err));
      });

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        setNotification(notification);
      }
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return { expoPushToken, notification };
}

const Notification = createContainer(useNotificationHooks);

export const NotificationProvider = Notification.Provider;
export const useNotificationProvider = Notification.useContainer;
