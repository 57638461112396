import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import {
  Card,
  ThemeProvider,
  TouchableRipple,
  withTheme,
} from "react-native-paper";
import { Theme } from "react-native-paper/lib/typescript/types";
import HTML from "react-native-render-html";
import { Column, Row, Spacer } from "../../rockts/ui-primitives";
import { margin, padding } from "../../styles/mixins";
import { standardTheme } from "../../styles/themes";

type Props = {
  title: string;
  theme: Theme;
  snippet?: string;
  term?: string;
  onPress: () => void;
};

const SearchItem: React.FC<Props> = ({
  title = "",
  term = "",
  onPress,
  snippet,
}) => {
  const tagStyles = {
    b: {
      fontStyle: "italic",
      backgroundColor: "#ECF4FC",
      color: "#5291DD",
    },
  };

  const highlightedTitle = title.replace(term, `<b>${term}</b>`);

  return (
    <ThemeProvider theme={standardTheme}>
      <TouchableRipple
        style={{ ...margin(0, 20, 20, 20) }}
        rippleColor="rgba(0, 0, 0, .32)"
      >
        <Card onPress={() => onPress && onPress()}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            {...padding(20)}
          >
            <Column flex={1}>
              <HTML
                baseFontStyle={{ fontSize: 16 }}
                containerStyle={{ flex: 1 }}
                source={{ html: highlightedTitle }}
                tagsStyles={tagStyles}
              />
              {snippet && (
                <Column>
                  <Spacer />
                  <Row>
                    <HTML
                      baseFontStyle={{ fontSize: 12 }}
                      source={{ html: snippet }}
                      tagsStyles={tagStyles}
                      containerStyle={{ flex: 1 }}
                    />
                  </Row>
                </Column>
              )}
            </Column>
            <MaterialIcons size={30} name="chevron-right" />
          </Row>
        </Card>
      </TouchableRipple>
    </ThemeProvider>
  );
};

export default withTheme(SearchItem);
