import {
  DefaultTheme,
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import * as Analytics from "expo-firebase-analytics";
import * as Notifications from "expo-notifications";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useRef } from "react";
import { ThemeProvider } from "react-native-paper";
import Toast from "react-native-toast-message";
import { PrivateNavigator } from "./navigation/PrivateNavigator";
import PublicNavigator from "./navigation/PublicNavigator";
import { linking } from "./navigation/routes";
import { useStateProvider } from "./providers/state.provider";
import { Client } from "./services/client";
import { registerForPushNotificationsAsync } from "./services/notification";
import { WHITE } from "./styles/colors";
import { getBrightness } from "./styles/mixins";

const AppContainer = () => {
  const { companyTheme, isReady, user, refreshState } = useStateProvider();

  const routeNameRef = useRef<any>();
  const navigationRef = useRef<NavigationContainerRef>(null);
  const brightness = getBrightness(companyTheme?.colors.primary);

  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const navigatorTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: companyTheme?.colors.surface ?? WHITE,
    },
  };

  useEffect(() => {
    if (!user || !user.id) return;

    Analytics.setUserId(user.id);
    Analytics.setUserProperties({
      companyId: user.companyId ?? "",
      departmentId: user.departmentId ?? "",
      acknowledged: user.acknowledged ? "yes" : "no",
    });

    registerForPushNotificationsAsync().then(
      async (token) => await Client.notificationSubscribe(user.id!, token!)
    );

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      async (notification) => {
        const { title, body } = notification.request.content;
        // Refresh state in case acknowledge is needed
        refreshState();

        Toast.show({
          position: "top",
          text1: title,
          text2: body,
          type: "info",
        });
      }
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [user]);

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={navigatorTheme}
      linking={linking}
      onReady={() =>
        (routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name)
      }
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          Analytics.setCurrentScreen(currentRouteName);
        }
        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      {isReady ? (
        <ThemeProvider theme={companyTheme}>
          <StatusBar style={brightness} />
          <PrivateNavigator />
        </ThemeProvider>
      ) : (
        <PublicNavigator />
      )}
    </NavigationContainer>
  );
};

export default AppContainer;
