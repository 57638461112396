import { MaterialIcons } from "@expo/vector-icons";
import { useLinkTo } from "@react-navigation/native";
import debounce from "lodash/debounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SafeAreaView, SectionList, StatusBar } from "react-native";
import {
  ActivityIndicator,
  Caption,
  Searchbar,
  Subheading,
  Surface,
  ThemeProvider,
} from "react-native-paper";
import { EmptyState } from "../components/atoms/emptyState";
import SearchItem from "../components/atoms/SearchItem";
import { useStateProvider } from "../providers/state.provider";
import { Container, Row, Spacer } from "../rockts";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { Client } from "../services/client";
import { SearchSectionResponseDto as SearchSection } from "../services/sdk";
import { WHITE } from "../styles/colors";
import { standardTheme } from "../styles/themes";

export const SearchScreen: React.FC<{}> = () => {
  const [query, setQuery] = useState<string>();
  const [results, setResults] = useState<SearchSection[] | null>(null);
  const searchBarRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const { handbook } = useStateProvider();

  const linkTo = useLinkTo();

  useEffect(() => {
    if (query === "") {
      setResults(null);
    }
  }, [query]);

  const handleSearch = useCallback(
    debounce(async (query: string) => {
      // Only search if has at least 3 characters
      try {
        if (query.length < 3) return;
        if (!handbook || !handbook.id) {
          throw Error("Cannot do search without handbook id");
        }
        setLoading(true);
        const results = await Client.search(handbook.id, query);

        setResults(results);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    }, 500),
    []
  );

  type ResultsProps = {
    results: SearchSection[] | null;
    loading: boolean;
    term: string;
  };

  const ResultsList: React.FC<ResultsProps> = ({
    results,
    term: searchTerm,
    loading,
  }) => {
    // To avoid re-renders cache query until results change
    const term = useMemo(() => searchTerm, [results]);
    const searchResults = useMemo(() => {
      return results?.map(({ title, introHeadline, subsections, id }) => {
        // If there is no subsections. The section was the result
        if (!subsections || subsections?.length === 0) {
          subsections = [{ title, contentHeadline: introHeadline, id }];
        }
        return {
          title: title ?? "",
          data: subsections,
        };
      });
    }, [results]);

    if (loading)
      return (
        <Container center flex={1}>
          <ActivityIndicator size={60} animating={loading} />
        </Container>
      );

    // IF term is less than 3 should ask the user to search
    if (term.length < 3) return <EmptyState message="Search In Hand Book" />;
    // If no search results or 0 search results could not find
    if (!searchResults || searchResults.length === 0)
      return <EmptyState message="Could not find any results" />;

    return (
      <Surface style={{ flex: 1, elevation: 0 }}>
        <MaxWidthView>
          <SectionList
            sections={searchResults}
            renderItem={({ item: section }) => (
              <SearchItem
                title={section.title ?? ""}
                snippet={section.contentHeadline ?? ""}
                term={term}
                onPress={() => {
                  if (section.sectionId) {
                    // If has a parent its a subsection
                    linkTo(`/handbook/subsection/${section.id}`);
                  } else {
                    linkTo(`/handbook/section/${section.id}`);
                  }
                }}
              />
            )}
            renderSectionHeader={({ section }) => {
              const resultsCount = section.data.length;

              const { title } = section;
              //TODO: bring this into another component

              return (
                <Surface style={{ elevation: 0 }}>
                  <Spacer />
                  <Row
                    paddingTop={0}
                    paddingLeft={20}
                    paddingRight={20}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Subheading>{title}</Subheading>

                    <Caption>{`${resultsCount} found`}</Caption>
                  </Row>

                  <Spacer />
                </Surface>
              );
            }}
          />
        </MaxWidthView>
      </Surface>
    );
  };

  return (
    <Container backgroundColor={WHITE} height="100%">
      <StatusBar barStyle="dark-content" />
      <ThemeProvider theme={standardTheme}>
        <SafeAreaView style={{ backgroundColor: WHITE, paddingTop: 20 }}>
          <Searchbar
            ref={searchBarRef}
            autoFocus={true}
            style={{ elevation: 0 }}
            clearIcon={() => <MaterialIcons size={25} name="close" />}
            // icon={() => <MaterialIcons size={25} name="arrow-back" />}
            // onIconPress={() => goBack()}
            placeholder="Search"
            onChangeText={(query) => {
              setQuery(query);
              handleSearch(query);
            }}
            value={query ?? ""}
          />
        </SafeAreaView>
      </ThemeProvider>

      <ResultsList results={results} loading={loading} term={query ?? ""} />
    </Container>
  );
};
