import { IsEmail, IsNotEmpty } from "class-validator";
import { _M } from "../../constants";

export class CredentialsDto {
  @IsNotEmpty({ message: _M.fields.required })
  @IsEmail()
  username!: string;

  @IsNotEmpty({ message: _M.fields.required })
  password!: string;
}
