import { DefaultTheme } from "react-native-paper";
import { Theme } from "react-native-paper/lib/typescript/types";
import { BLACK, PRIMARY, SECONDARY, WHITE } from "./colors";

export const standardTheme: Theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    background: WHITE,
    surface: WHITE,
    text: BLACK,
    primary: BLACK,
    accent: WHITE,
  },
};

export const ihbTheme: Theme = {
  ...DefaultTheme,
  roundness: 10,

  colors: {
    ...DefaultTheme.colors,
    primary: PRIMARY,
    accent: PRIMARY,
    surface: WHITE,
    text: SECONDARY,
  },
};
