import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FunctionComponent } from "react";
import { Linking, RefreshControl, View } from "react-native";
import {
  ActivityIndicator,
  Card,
  Headline,
  ThemeProvider,
} from "react-native-paper";
import HTML from "react-native-render-html";
import { Divider } from "../components/atoms/Divider";
import { Wrapper } from "../components/atoms/Wrapper";
import { PrivateNavigatorParams } from "../navigation";
import { Column, Container, Row, Spacer } from "../rockts/ui-primitives";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { useAsync } from "../rockts/useAsync";
import { Client } from "../services/client";
import { standardTheme } from "../styles/themes";

type NavigationProps = StackNavigationProp<
  PrivateNavigatorParams,
  "SubSection"
>;
type RouteProps = RouteProp<PrivateNavigatorParams, "SubSection">;
type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

export const SubSectionScreen: FunctionComponent<Props> = (props) => {
  const { subsectionId } = props.route.params;

  const { data, isPending, execute } = useAsync(
    Client.getSubsectionById,
    true,
    [subsectionId]
  );

  if (isPending && !data) {
    return (
      <Container flex={1} center>
        <ActivityIndicator animating={true} size={50} />
      </Container>
    );
  }

  if (!data) return <View />;

  return (
    <Container flex={1}>
      <Wrapper
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={isPending} onRefresh={execute} />
        }
      >
        <MaxWidthView>
          <Spacer />
          <Column>
            <Headline style={{ textAlign: "center", margin: 20 }}>
              {data.title}
            </Headline>
            <Spacer />
            <Divider />
            <Spacer />
            <ThemeProvider theme={standardTheme}>
              <Row>
                <Spacer />
                <Card style={{ flex: 1 }}>
                  <Card.Content>
                    <HTML
                      baseFontStyle={{ fontSize: 18 }}
                      source={{ html: data.content ?? "" }}
                      onLinkPress={(_: any, url: string) => {
                        Linking.openURL(url);
                      }}
                    />
                  </Card.Content>
                </Card>
                <Spacer />
              </Row>
            </ThemeProvider>
          </Column>
          <Spacer />
        </MaxWidthView>
      </Wrapper>
    </Container>
  );
};
