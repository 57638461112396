import { useLinkTo } from "@react-navigation/native";
import React from "react";
import { Image, Keyboard, KeyboardAvoidingView, Platform } from "react-native";
import { Button, Card, Headline, TextInput } from "react-native-paper";
import {
  useLoading,
  useNotifyError,
} from "../../providers/overlay/overlay.bloc";
import { useDimensions } from "../../rockts";
import { Column, Spacer } from "../../rockts/ui-primitives";
import { useFieldValidator } from "../../rockts/use-dto-validator";
import { Client } from "../../services/client";
import { CredentialsDto } from "../../services/dto/credentials.dto";
import { DARK_GRAY, WHITE } from "../../styles/colors";
import s from "./styles";

export const LoginScreen = () => {
  const [fields, updateField] = useFieldValidator(new CredentialsDto());
  const notifyError = useNotifyError();

  const showloading = useLoading();
  const dimensions = useDimensions("window");
  const linkTo = useLinkTo();

  const handleSubmit = async () => {
    Keyboard.dismiss();
    setTimeout(async () => {
      // Dismiss keybord before calling sign in
      await handleSignIn();
    }, 200);
  };

  const handleSignIn = async () => {
    try {
      showloading(true);
      const { username, password } = fields;

      await Client.signIn({ username, password });
    } catch (err) {
      notifyError(err);
    } finally {
      showloading(false);
    }
  };

  const isLargeScreen = dimensions.width > 768;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS == "ios" ? "padding" : "height"}
      style={{ flex: 1, backgroundColor: WHITE, alignItems: "center" }}
    >
      <Column
        flex={1}
        width={isLargeScreen ? 400 : "100%"}
        justifyContent="center"
      >
        <Image
          resizeMode="contain"
          style={s.logo}
          source={require("../../assets/logo.png")}
        />
        <Card style={s.card}>
          <Card.Content style={{ justifyContent: "space-between" }}>
            <Headline style={s.headline}>Sign In</Headline>
            <Spacer />
            <TextInput
              //TODO: Abstract TextInput and its styling
              style={s.textInput}
              theme={{ colors: { primary: DARK_GRAY } }}
              value={fields.username}
              mode="outlined"
              autoCorrect={false}
              autoCapitalize="none"
              onChangeText={(text) => updateField("username", text)}
              label="Email"
            />
            <Spacer />
            <TextInput
              style={s.textInput}
              theme={{ colors: { primary: DARK_GRAY } }}
              mode="outlined"
              value={fields.password}
              returnKeyType="done"
              label="Password"
              autoCorrect={false}
              autoCapitalize="none"
              onSubmitEditing={handleSubmit}
              onChangeText={(text) => updateField("password", text)}
              secureTextEntry
            />
            <Spacer />
            <Button
              contentStyle={s.button}
              labelStyle={s.buttonLabel}
              mode="contained"
              onPress={handleSubmit}
            >
              Login
            </Button>
            <Spacer />
            <Button
              labelStyle={s.forgotPassLabel}
              onPress={() => {
                linkTo("/forgot-password");
              }}
            >
              Forgot Password?
            </Button>
            <Spacer space={10} />
          </Card.Content>
        </Card>
      </Column>
      <Image
        style={s.background}
        source={require("../../assets/background.png")}
      />
    </KeyboardAvoidingView>
  );
};
