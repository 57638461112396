import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import {
  Card,
  ThemeProvider,
  Title,
  TouchableRipple,
} from "react-native-paper";
import { Column, Row } from "../../rockts/ui-primitives";
import { margin, padding } from "../../styles/mixins";
import { standardTheme } from "../../styles/themes";

type Props = {
  title?: string;

  onPress: () => void;
};

const ListItem: React.FC<Props> = ({ title, onPress }) => {
  return (
    <ThemeProvider theme={standardTheme}>
      <TouchableRipple
        style={{ ...margin(10, 20) }}
        rippleColor="rgba(0, 0, 0, .32)"
      >
        <Card onPress={() => onPress && onPress()}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            {...padding(20)}
          >
            <Column flex={1}>
              <Title>{title}</Title>
            </Column>
            <MaterialIcons size={30} name="chevron-right" />
          </Row>
        </Card>
      </TouchableRipple>
    </ThemeProvider>
  );
};

export default ListItem;
