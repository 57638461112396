import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import * as React from "react";
import { useStateProvider } from "../../providers/state.provider";
import { BLACK } from "../../styles/colors";
import { convertHexToRGBA } from "../../styles/mixins";
import { HandbookStack } from "./handbook-stack";
import { HomeStack } from "./home-stack";
import { SearchStack } from "./search-stack";

type MaterialBottomTabParams = {
  Home: undefined;
  Handbook: undefined;
  Search: undefined;
};

const BottomTabsNavigator = createBottomTabNavigator<MaterialBottomTabParams>();

export default function BottomTabNavigator() {
  const { companyTheme } = useStateProvider();
  const primaryColor = companyTheme?.colors.primary ?? BLACK;

  return (
    <BottomTabsNavigator.Navigator
      tabBarOptions={{
        activeTintColor: primaryColor,
        inactiveTintColor: convertHexToRGBA(primaryColor, 50),
      }}
    >
      <BottomTabsNavigator.Screen
        name="Home"
        options={{
          tabBarIcon: (props) => (
            <MaterialCommunityIcons name="home" size={25} color={props.color} />
          ),
        }}
      >
        {() => <HomeStack />}
      </BottomTabsNavigator.Screen>
      <BottomTabsNavigator.Screen
        name="Handbook"
        options={{
          tabBarIcon: (props) => (
            <MaterialCommunityIcons
              name="book-open-variant"
              size={25}
              color={props.color}
            />
          ),
        }}
      >
        {() => <HandbookStack />}
      </BottomTabsNavigator.Screen>
      <BottomTabsNavigator.Screen
        name="Search"
        options={{
          tabBarIcon: (props) => (
            <MaterialCommunityIcons
              name="magnify"
              size={25}
              color={props.color}
            />
          ),
        }}
      >
        {() => <SearchStack />}
      </BottomTabsNavigator.Screen>
    </BottomTabsNavigator.Navigator>
  );
}
