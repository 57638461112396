//Labels
export const _L = {
  sign_in: 'Login',
};

//Messages
export const _M = {
  fields: {
    required: 'Field is required.',
    short_password: 'Needs more than 6 characters.',
  },
};
