import { useLinkTo } from "@react-navigation/native";
import React from "react";
import { Button, Headline, Subheading, useTheme } from "react-native-paper";
import { useStateProvider } from "../providers/state.provider";
import { Column, Container, Row, Spacer } from "../rockts";
import { Client } from "../services/client";

export const AcknowledgeScreen = () => {
  const { user, handbook } = useStateProvider();

  const linkTo = useLinkTo();
  const theme = useTheme();
  const contrastColor = theme.colors.text;

  const handleAcknowledge = async () => {
    if (!handbook?.id || !user.id) {
      throw Error("Cannot submit acknowledgement");
    }
    await Client.acknowledge(handbook.id, user.id);
    // Need to refresh token to update user status
    // This will trigger a auth refresh
    await Client.refreshToken();
  };
  return (
    <Container center flex={1} margin={20}>
      <Column
        borderColor={contrastColor}
        borderWidth={1}
        borderRadius={5}
        padding={40}
      >
        <Headline style={{ textAlign: "center" }}>
          Thank you for taking the time to review the handbook.{" "}
        </Headline>
        <Spacer />
        <Subheading style={{ textAlign: "center" }}>
          Please acknowledge the receipt of the Employee Handbook in order to
          review the content.{" "}
        </Subheading>
        <Spacer />
        <Row justifyContent="center">
          <Button mode="contained" onPress={handleAcknowledge}>
            Acknowledge
          </Button>
        </Row>
      </Column>
    </Container>
  );
};
