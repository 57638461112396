import { useNavigation } from "@react-navigation/native";
import isEmpty from "lodash/isEmpty";
import React, { Fragment } from "react";
import { Pressable, View } from "react-native";
import { Divider, Paragraph, Text, Title } from "react-native-paper";
import { useStateProvider } from "../../providers/state.provider";
import { Column, Container, Row, Spacer } from "../../rockts";
import { InquiryTypeDto } from "../../services/sdk";
import { BLACK } from "../../styles/colors";
import { getContrast } from "../../styles/mixins";

type Props = {
  inquiries?: InquiryTypeDto[];
};

const InquirySection: React.FC<Props> = ({ inquiries }) => {
  const navigation = useNavigation();
  const { companyTheme } = useStateProvider();
  const primaryColor = companyTheme?.colors.primary ?? BLACK;
  const textColor = getContrast(primaryColor);

  if (inquiries === undefined || isEmpty(inquiries)) {
    return <View />;
  }
  return (
    <Fragment>
      <Divider />
      <Spacer />
      <Column justifyContent="center" alignItems="center">
        <Title>Have a Question?</Title>
        <Paragraph style={{ textAlign: "center" }}>
          Submit your inquiry below and a representative from your company will
          get back to you.
        </Paragraph>
      </Column>
      <Spacer />
      <Row flexWrap="wrap" paddingHorizontal={10}>
        {inquiries.map((inquiryType, id) => {
          return (
            <Column width="50%" key={id}>
              <Container margin={10}>
                <Pressable
                  // mode="contained"

                  style={{
                    height: 60,
                    backgroundColor: primaryColor,
                    justifyContent: "center",
                    alignContent: "center",
                    padding: 5,
                  }}
                  onPress={() =>
                    navigation.navigate(`Inquiry`, {
                      inquiryTypeId: inquiryType.id,
                      title: inquiryType.name,
                    })
                  }
                >
                  <Column>
                    <Text
                      style={{
                        color: textColor,
                        textAlign: "center",
                      }}
                      numberOfLines={2}
                    >
                      {inquiryType.name || ""}
                    </Text>
                  </Column>
                </Pressable>
              </Container>
            </Column>
          );
        })}
      </Row>
      <Spacer />
    </Fragment>
  );
};

export default InquirySection;
