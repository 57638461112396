import React from "react";
import JSONTree from "react-native-json-tree";

interface DisplayDataProps {
  data: any;
}

const DisplayData = (props: DisplayDataProps) => {
  const { data } = props;

  return (
    <JSONTree
      data={data}
      theme={monokaiTheme}
      invertTheme={false}
      hideRoot={true}
    />
  );
};

export default DisplayData;

const monokaiTheme = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#000000",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#a6e22e",
  base0C: "#a1efe4",
  base0D: "#66d9ef",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};
