/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AuthService {
  /**
   *
   */
  static authSignIn(
    params: {
      /** requestBody */
      body?: AuthCredentialsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/signin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authRefresh(
    params: {
      /** requestBody */
      body?: AuthRefreshDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authResetPassword(
    params: {
      /** requestBody */
      body?: AuthResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authResetCheckToken(
    params: {
      /** The reset token to check */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/{token}';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authResetUpdatePassword(
    params: {
      /** The reset token */
      token: string;
      /** requestBody */
      body?: AuthUpdatePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/{token}';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static userGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyUserResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userCreateOne(
    params: {
      /** requestBody */
      body?: CreateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompanyService {
  /**
   *
   */
  static companyGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCompanyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete one company
   */
  static companyDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyCompanyResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyCreateOne(
    params: {
      /** requestBody */
      body?: CreateCompanyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyGetManyHandbooks(
    params: {
      /**  */
      companyId: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyHandbookResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{companyId}/handbook';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyGetManyInquiryTypes(
    params: {
      /**  */
      companyId: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyInquiryTypeResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{companyId}/inquiry-type';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HandbookService {
  /**
   *
   */
  static handbookSearch(
    params: {
      /**  */
      id: string;
      /** These are the web-style search terms to be parsed by the PostgreSQL `websearch_to_tsquery()` function. */
      webSearch: string;
      /**  */
      limit?: number;
      /** Set to true to include subsections in search. */
      deep?: boolean;
      /** Set to true to return a headline string for section introductions */
      sectionIntroHeadline?: boolean;
      /** Set to true to return a headline string for subsection content */
      subsectionContentHeadline?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchSectionResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/handbook/{id}/search';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        webSearch: params['webSearch'],
        limit: params['limit'],
        deep: params['deep'],
        sectionIntroHeadline: params['sectionIntroHeadline'],
        subsectionContentHeadline: params['subsectionContentHeadline']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static handbookGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Handbook> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/handbook/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static handbookUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateHandbookDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Handbook> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/handbook/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static handbookGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyHandbookResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/handbook';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static handbookGetManySections(
    params: {
      /**  */
      handbookId: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManySectionResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/handbook/{handbookId}/section';
      url = url.replace('{handbookId}', params['handbookId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SectionService {
  /**
   *
   */
  static sectionMoveOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: MoveRecordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section/{id}/move';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Section> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Section> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Section> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManySectionResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionCreateOne(
    params: {
      /** requestBody */
      body?: CreateSectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Section> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sectionGetManySubsections(
    params: {
      /**  */
      sectionId: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManySubsectionResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/section/{sectionId}/subsection';
      url = url.replace('{sectionId}', params['sectionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SubsectionService {
  /**
   *
   */
  static subsectionMoveOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: MoveRecordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection/{id}/move';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subsectionGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Subsection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subsectionUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSubsectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Subsection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subsectionDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Subsection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subsectionGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManySubsectionResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subsectionCreateOne(
    params: {
      /** requestBody */
      body?: CreateSubsectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Subsection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/subsection';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InquiryTypeService {
  /**
   *
   */
  static sectionMoveOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: MoveRecordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type/{id}/move';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquirySend(
    params: {
      /** requestBody */
      body?: SendInquiryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type/send';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquiryTypeGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InquiryTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquiryTypeUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateInquiryTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InquiryTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquiryTypeDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InquiryTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquiryTypeGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyInquiryTypeDtoResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inquiryTypeCreateOne(
    params: {
      /** requestBody */
      body?: CreateInquiryTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InquiryTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inquiry-type';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DepartmentService {
  /**
   *
   */
  static departmentGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Department> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/department/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static departmentUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateDepartmentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Department> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/department/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static departmentDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Department> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/department/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static departmentGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyDepartmentResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/department';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static departmentCreateOne(
    params: {
      /** requestBody */
      body?: CreateDepartmentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Department> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/department';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UploadsService {
  /**
   *
   */
  static uploadsSign(
    params: {
      /**  */
      objectName: string;
      /**  */
      contentType: string;
      /** Set to true to sign a file that will be private. */
      hidden: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/uploads/sign';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        objectName: params['objectName'],
        contentType: params['contentType'],
        hidden: params['hidden']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadsControllerPublicFileRedirect(
    params: {
      /**  */
      objectName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/uploads/public/{objectName}';
      url = url.replace('{objectName}', params['objectName'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadsControllerPrivateFileRedirect(
    params: {
      /**  */
      objectName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/uploads/private/{objectName}';
      url = url.replace('{objectName}', params['objectName'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationService {
  /**
   *
   */
  static notificationSendCompany(
    params: {
      /** requestBody */
      body?: CompanyPushNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationSentDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification/sendCompany';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static notificationSendHandbook(
    params: {
      /** requestBody */
      body?: HandbookPushNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationSentDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification/sendHandbook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static notificationSubscribe(
    params: {
      /** requestBody */
      body?: SubscribeNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification/subscribe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static notificationUnsubscribe(
    params: {
      /** requestBody */
      body?: UnsubscribeNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification/unsubscribe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DeviceService {
  /**
   *
   */
  static deviceGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/device/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deviceUpdateOne(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateDeviceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/device/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deviceDeleteOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/device/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deviceGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyDeviceResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/device';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deviceCreateOne(
    params: {
      /** requestBody */
      body?: CreateDeviceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/device';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AckService {
  /**
   *
   */
  static ackGetOne(
    params: {
      /**  */
      id: string;
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Acknowledgment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ack/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fields: params['fields'], join: params['join'], cache: params['cache'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static ackGetMany(
    params: {
      /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
      fields?: any | null[];
      /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
      s?: string;
      /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
      filter?: any | null[];
      /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
      or?: any | null[];
      /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
      sort?: any | null[];
      /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
      join?: any | null[];
      /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
      limit?: number;
      /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
      offset?: number;
      /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
      page?: number;
      /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
      cache?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetManyAcknowledgmentResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ack';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fields: params['fields'],
        s: params['s'],
        filter: params['filter'],
        or: params['or'],
        sort: params['sort'],
        join: params['join'],
        limit: params['limit'],
        offset: params['offset'],
        page: params['page'],
        cache: params['cache']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static ackCreateOne(
    params: {
      /** requestBody */
      body?: CreateAckDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Acknowledgment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ack';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InvitationService {
  /**
   *
   */
  static authInvite(
    params: {
      /** requestBody */
      body?: InviteUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invitation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AuthCredentialsDto {
  /** The user's username (or email address). */
  username?: string;

  /** The user's password */
  password?: string;

  /** Optionally provide an array of role(s) the user is expected to have in order to authenticate. This, of course, does not grant additional access, and is provided only as a convenience. */
  assertRoles?: string[];
}

export interface AuthResponse {
  /**  */
  accessToken?: string;

  /**  */
  refreshToken?: string;
}

export interface UnauthorizedException {}

export interface AuthRefreshDto {
  /**  */
  refreshToken?: string;
}

export interface AuthResetPasswordDto {
  /**  */
  email?: string;

  /**  */
  isAdmin?: boolean;
}

export interface AuthUpdatePasswordDto {
  /**  */
  password?: string;
}

export interface GetManyUserResponseDto {
  /**  */
  data?: User[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface User {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  username?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  acknowledged?: boolean;

  /**  */
  companyId?: string;

  /**  */
  departmentId?: string;
}

export interface CreateUserDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  acknowledged?: boolean;

  /**  */
  companyId?: string;

  /**  */
  departmentId?: string;
}

export interface UpdateUserDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  acknowledged?: boolean;

  /**  */
  companyId?: string;

  /**  */
  departmentId?: string;
}

export interface GetManyCompanyResponseDto {
  /**  */
  data?: Company[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface UploadsFileMetaDto {
  /**  */
  name?: string;

  /**  */
  url?: string;
}

export interface CompanyViewMetaDto {
  /** Primary color (hex) */
  primaryColor?: string;

  /** Secondary color (hex) */
  secondaryColor?: string;

  /** Company logo URL */
  logoUrl?: UploadsFileMetaDto[];

  /** Company video URL. Only YouTube and Vimeo are currently supported. */
  videoUrl?: string;
}

export interface GetManyInquiryTypeDtoResponseDto {
  /**  */
  data?: InquiryTypeDto[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface InquiryTypeDto {
  /** The primary key of the resource. */
  id?: string;

  /** The date and time at which the resource was created. */
  createdAt?: Date;

  /** The date and time at which the resource was last updated. */
  updatedAt?: Date;

  /** Name of the Inquiry Type */
  name?: string;

  /** Email that content will be sent */
  recipientEmail?: string;

  /** Content of the email that will be sent */
  content?: string;

  /** sort order of the inquiries */
  sortPriority?: number;

  /** Company Id of inquiry type */
  companyId?: string;
}

export interface Company {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /** Company name */
  name?: string;

  /**  */
  nameSlug?: string;

  /** Company welcome text */
  welcomeText?: string;

  /**  */
  viewMeta?: CompanyViewMetaDto;

  /**  */
  inquiryTypes?: InquiryTypeDto[];
}

export interface UpdateCompanyViewMetaDto {
  /** Primary color (hex) */
  primaryColor?: string;

  /** Secondary color (hex) */
  secondaryColor?: string;

  /** Company logo URL */
  logoUrl?: UploadsFileMetaDto[];

  /** Company video URL. Only YouTube and Vimeo are currently supported. */
  videoUrl?: string;
}

export interface CreateCompanyDto {
  /** The name of the company. */
  name?: string;

  /** The company welcome text. */
  welcomeText?: string;

  /**  */
  viewMeta?: CombinedViewMetaTypes;
}

export interface UpdateCompanyDto {
  /** The name of the company. */
  name?: string;

  /** The company welcome text. */
  welcomeText?: string;

  /**  */
  viewMeta?: CombinedViewMetaTypes;
}

export interface GetManyHandbookResponseDto {
  /**  */
  data?: Handbook[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Handbook {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  title?: string;

  /**  */
  versionName?: string;

  /**  */
  versionNumber?: string;

  /**  */
  companyId?: string;
}

export interface GetManyInquiryTypeResponseDto {
  /**  */
  data?: InquiryType[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface InquiryType {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  name?: string;

  /**  */
  recipientEmail?: string;

  /**  */
  content?: string;

  /**  */
  sortPriority?: number;

  /**  */
  companyId?: string;

  /**  */
  nameSlug?: string;
}

export interface SearchSubsectionResponseDto {
  /**  */
  id?: string;

  /**  */
  title?: string;

  /**  */
  contentHeadline?: string;

  /**  */
  sectionId?: string;

  /**  */
  sectionTitle?: string;
}

export interface SearchSectionResponseDto {
  /**  */
  id?: string;

  /**  */
  title?: string;

  /**  */
  subsections?: SearchSubsectionResponseDto[];

  /**  */
  introHeadline?: string;
}

export interface UpdateHandbookDto {
  /** The title of the handbook. */
  title?: string;

  /** The version name of the handbook. */
  versionName?: string;
}

export interface GetManySectionResponseDto {
  /**  */
  data?: Section[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Section {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  title?: string;

  /**  */
  introduction?: string;

  /**  */
  sortPriority?: number;

  /**  */
  handbookId?: string;
}

export interface MoveRecordDto {}

export interface CreateSectionDto {
  /** The title of the section. */
  title?: string;

  /** The introduction of the section. */
  introduction?: string;

  /** The sort priority of the section. */
  sortPriority?: number;

  /** The id of the parent handbook of the section. */
  handbookId?: string;
}

export interface UpdateSectionDto {
  /** The title of the section. */
  title?: string;

  /** The introduction of the section. */
  introduction?: string;

  /** The sort priority of the section. */
  sortPriority?: number;
}

export interface GetManySubsectionResponseDto {
  /**  */
  data?: Subsection[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Subsection {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  sortPriority?: number;

  /**  */
  sectionId?: string;
}

export interface CreateSubsectionDto {
  /** The title of the subsection. */
  title?: string;

  /** The content of the subsection. */
  content?: string;

  /** The sort priority of the subsection. */
  sortPriority?: number;

  /** The id of the parent section of the subsection. */
  sectionId?: string;
}

export interface UpdateSubsectionDto {
  /** The title of the subsection. */
  title?: string;

  /** The content of the subsection. */
  content?: string;

  /** The sort priority of the subsection. */
  sortPriority?: number;
}

export interface SendInquiryDto {
  /**  */
  inquiryTypeId?: string;

  /** The content user defined to be send to email */
  content?: string;
}

export interface CreateInquiryTypeDto {
  /** Name of the Inquiry Type */
  name?: string;

  /** Email that content will be sent */
  recipientEmail?: string;

  /** Content of the email that will be sent */
  content?: string;

  /** Company Id of inquiry type */
  companyId?: string;

  /** sort order of the inquiries */
  sortPriority?: number;
}

export interface UpdateInquiryTypeDto {
  /** Name of the Inquiry Type */
  name?: string;

  /** Email that content will be sent */
  recipientEmail?: string;

  /** Content of the email that will be sent */
  content?: string;

  /** sort order of the inquiries */
  sortPriority?: number;
}

export interface GetManyDepartmentResponseDto {
  /**  */
  data?: Department[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Department {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  nameSlug?: string;

  /**  */
  companyId?: string;
}

export interface CreateDepartmentDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  companyId?: string;
}

export interface UpdateDepartmentDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  companyId?: string;
}

export interface UploadsFile {
  /** The url to retrieve the resource */
  url?: string;

  /** If the resource is private, this will be true. */
  hidden?: boolean;

  /** Number of seconds until access to the resource expires. */
  expiresIn?: number;
}

export interface CompanyPushNotificationDto {
  /**  */
  title?: string;

  /**  */
  message?: string;

  /**  */
  companyId?: string;

  /**  */
  departmentId?: string;

  /** If true, only notify users that HAVE acknowledged. If false, only send to users that HAVE NOT acknowledged. */
  userAcknowledged?: boolean;
}

export interface PushNotificationSentDto {
  /** Randomly generated UUID */
  id?: string;
}

export interface HandbookPushNotificationDto {
  /**  */
  title?: string;

  /**  */
  message?: string;

  /**  */
  handbookId?: string;

  /** If true, force all users to acknowledge. */
  forceAcknowledge?: boolean;
}

export interface SubscribeNotificationDto {
  /** The device operating system type. */
  deviceType?: EnumSubscribeNotificationDtoDeviceType;

  /** The device token string. */
  deviceToken?: string;

  /** The firebase push token for this device. */
  fcmToken?: string;

  /** The user id that owns this device. */
  userId?: string;
}

export interface UnsubscribeNotificationDto {
  /**  */
  fcmToken?: string;
}

export interface GetManyDeviceResponseDto {
  /**  */
  data?: Device[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Device {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  deviceType?: string;

  /**  */
  deviceToken?: string;

  /**  */
  fcmToken?: string;

  /**  */
  userId?: string;
}

export interface CreateDeviceDto {
  /** The device operating system type. */
  deviceType?: EnumCreateDeviceDtoDeviceType;

  /** The device token string. */
  deviceToken?: string;

  /** The firebase push token for this device. */
  fcmToken?: string;

  /** The user id that owns this device. */
  userId?: string;
}

export interface UpdateDeviceDto {
  /** The device operating system type. */
  deviceType?: EnumUpdateDeviceDtoDeviceType;

  /** The device token string. */
  deviceToken?: string;

  /** The firebase push token for this device. */
  fcmToken?: string;
}

export interface GetManyAcknowledgmentResponseDto {
  /**  */
  data?: Acknowledgment[];

  /**  */
  count?: number;

  /**  */
  total?: number;

  /**  */
  page?: number;

  /**  */
  pageCount?: number;
}

export interface Acknowledgment {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  versionName?: string;

  /**  */
  versionNumber?: string;

  /**  */
  userId?: string;

  /**  */
  handbookId?: string;
}

export interface CreateAckDto {
  /**  */
  userId?: string;

  /**  */
  handbookId?: string;
}

export interface InviteUserDto {
  /**  */
  userId?: string;
}
export type CombinedViewMetaTypes = (UpdateCompanyViewMetaDto & any) | null;
export enum EnumSubscribeNotificationDtoDeviceType {
  'ios' = 'ios',
  'android' = 'android',
  'web' = 'web'
}
export enum EnumCreateDeviceDtoDeviceType {
  'ios' = 'ios',
  'android' = 'android',
  'web' = 'web'
}
export enum EnumUpdateDeviceDtoDeviceType {
  'ios' = 'ios',
  'android' = 'android',
  'web' = 'web'
}
