export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GRAY = '#CCCCCC';
export const DARK_GRAY = '#777777';

export const PRIMARY = '#77C8A7';
export const SECONDARY = '#324158';
export const ACCENT = '#F3BD5C';

function convertHex(hex: string, opacity: number) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  return result;
}
