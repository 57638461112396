import { createDrawerNavigator } from "@react-navigation/drawer";
import React from "react";
import { DrawerMenu } from "./components";
import BottomTabNavigator from "./private-stacks/BottomTabNavigator";

const Drawer = createDrawerNavigator();

export type PrivateNavigatorParams = {
  Home: undefined;
  Handbook: undefined;
  Sections: { sectionId: string };
  SubSection: { subsectionId: string };
  Inquiry: { inquiryTypeId: string };
};

export const PrivateNavigator = () => {
  return (
    <Drawer.Navigator
      drawerType={"front"}
      drawerContent={(props) => <DrawerMenu {...props} />}
    >
      <Drawer.Screen name="Home" component={BottomTabNavigator} />
    </Drawer.Navigator>
  );
};
