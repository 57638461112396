/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { LoadingOverlay } from "./components";
import { SnackbarOverlay } from "./components/SnackbarOverlay";

// Create OverlayBloc as a context
const Bloc = createContext<OverlayBlocValues>({} as OverlayBlocValues);

export const useLoading = () =>
  useContextSelector(Bloc, (state) => state.setShowLoading);

export const useNotify = () =>
  useContextSelector(Bloc, (state) => state.notify);

export const useNotifyError = () =>
  useContextSelector(Bloc, (state) => state.notifyError);
// Create a simple hook to use

export const useOverlayBloc = () => useContext(Bloc);
// Constumer for the bloc

interface OverlayBlocValues {
  setShowLoading: Dispatch<SetStateAction<boolean>>;
  notify: (message: string) => void;
  notifyError: (error: Error) => void;
}

export const OverlayProvider: React.FC<{}> = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string | undefined>();

  const notify = (message: string) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);
  };

  const notifyError = (err: any) => {
    setShowSnackbar(true);
    const defaultMessage = "Something went wrong.";
    const axiosMsg = err.response?.data?.message;
    const errorMsg = err.message;
    setSnackbarMessage(axiosMsg || errorMsg || defaultMessage);
  };

  const clearSnackbar = () => {
    setSnackbarMessage(undefined);
    setShowSnackbar(false);
  };

  const values: OverlayBlocValues = {
    setShowLoading,
    notify,
    notifyError,
  };

  return (
    <Bloc.Provider value={values}>
      <LoadingOverlay visible={showLoading} />
      <SnackbarOverlay
        show={showSnackbar}
        clear={clearSnackbar}
        message={snackBarMessage ?? ""}
      />
      {props.children}
    </Bloc.Provider>
  );
};
