import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";
import { useStateProvider } from "../../providers/state.provider";
import { AcknowledgeScreen } from "../../screens/acknowledge-screen";
import { HandBookScreen } from "../../screens/handbook-screen";
import { SectionScreen } from "../../screens/section-screen";
import { SubSectionScreen } from "../../screens/subsection-screen";
import { Header } from "../components/Header";

const Stack = createStackNavigator();

export const HandbookStack = () => {
  const { user } = useStateProvider();
  if (!user?.acknowledged) {
    return (
      <Stack.Navigator headerMode="screen">
        <Stack.Screen
          name="Acknowledge"
          component={AcknowledgeScreen}
          options={{
            title: "Acknowledge",
            header: () => <View />,
          }}
        />
      </Stack.Navigator>
    );
  }
  return (
    <Stack.Navigator
      initialRouteName="Handbook"
      headerMode="screen"
      screenOptions={{
        header: (props) => <Header {...props} />,
      }}
    >
      <Stack.Screen
        name="Handbook"
        component={HandBookScreen}
        options={{
          title: "Employee Handbook",
        }}
      />

      <Stack.Screen
        name="Section"
        component={SectionScreen}
        options={{
          title: "Section",
        }}
      />

      <Stack.Screen
        name="Subsection"
        component={SubSectionScreen}
        options={{
          title: "Policy",
        }}
      />
    </Stack.Navigator>
  );
};
