import React from "react";
import { Divider as PDivider, useTheme } from "react-native-paper";

export const Divider = () => {
  const { colors } = useTheme();
  return (
    <PDivider
      style={{
        backgroundColor: colors.text,
        height: 2,
        width: "30%",
        alignSelf: "center",
      }}
    />
  );
};
