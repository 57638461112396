import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { getClaims, isAuthenticated, onResetTokens, onSetTokens } from ".";
import { AsyncReturnType } from "./types";

type FetchUser<T = any> = (id: string) => Promise<T>;

type FetchUserReturn<T> = AsyncReturnType<FetchUser<T>>;

type AuthProviderProps = {
  fetchUser: FetchUser;
};

function useAuthUser<T>(fn: FetchUser<T>) {
  const [authUser, setAuthUser] = useState<FetchUserReturn<T>>();

  const fetchAuthedUser = async () => {
    const claims = await getClaims();
    if ((await isAuthenticated()) && claims?.sub) {
      try {
        const user = await fn(claims?.sub);

        setAuthUser(user);
      } catch (error) {
        console.error(error);
      }
    } else {
      setAuthUser(undefined);
    }
  };

  useEffect(() => {
    fetchAuthedUser();
    const resetTokens = onResetTokens(fetchAuthedUser);
    const setTokens = onSetTokens(fetchAuthedUser);
    return () => {
      resetTokens.unsubscribe();
      setTokens.unsubscribe();
    };
  }, []);
  return authUser;
}

function useIsAuthenticated(initialState = false) {
  const [authenticated, setAuthenticated] = useState(initialState);

  const checkAuth = async () => {
    const isAuthed = await isAuthenticated();
    setAuthenticated(isAuthed);
  };

  useEffect(() => {
    checkAuth();
    const resetTokens = onResetTokens(checkAuth);
    const setTokens = onSetTokens(checkAuth);
    return () => {
      resetTokens.unsubscribe();
      setTokens.unsubscribe();
    };
  }, []);

  return authenticated;
}

function useAuthHooks(initialState?: AuthProviderProps) {
  if (!initialState) {
    throw Error("Missing initial state to AuthProvider");
  }
  const { fetchUser } = initialState;
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser(fetchUser);
  return { isAuthenticated, authUser };
}

const Auth = createContainer(useAuthHooks);

export const AuthProvider = Auth.Provider;
export const useAuthProvider = Auth.useContainer;
