import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { Fragment, useCallback } from "react";
import { ListRenderItem, RefreshControl, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ActivityIndicator, Headline, Subheading } from "react-native-paper";
import { Divider } from "../components/atoms/Divider";
import ListItem from "../components/atoms/ListItem";
import { PrivateNavigatorParams } from "../navigation";
import { useStateProvider } from "../providers/state.provider";
import { Column, Container, Spacer } from "../rockts/ui-primitives";
import { MaxWidthView } from "../rockts/ui-primitives/MaxWidthView";
import { useAsync } from "../rockts/useAsync";
import { Client } from "../services/client";
import { Section, Subsection } from "../services/sdk";

type NavigationProps = StackNavigationProp<PrivateNavigatorParams, "Sections">;
type RouteProps = RouteProp<PrivateNavigatorParams, "Sections">;
type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

export const SectionScreen: React.FC<Props> = (props) => {
  const { sectionId } = props.route.params;
  const { handbookSections } = useStateProvider();

  const linkTo = useLinkTo();

  const loadData = useCallback(async () => {
    if (!sectionId || !handbookSections) return;

    const currentSection = handbookSections.find((item) => {
      return item.id === sectionId;
    });

    if (!currentSection || !currentSection.id) {
      throw Error("Handbook section does not exist.");
    }

    const subsections = await Client.getSubSections(currentSection.id);

    return { currentItem: currentSection, subsections };
  }, [sectionId, handbookSections]);

  const { data, isPending, error, execute } = useAsync(loadData, true);

  const renderItem: ListRenderItem<Subsection> = ({ item }) => {
    const { title, id } = item;
    return (
      <ListItem
        title={title || ""}
        key={id}
        onPress={() => linkTo(`/handbook/subsection/${id}`)}
      />
    );
  };

  const renderHeader = (currentSection: Section) => {
    const { title, introduction } = currentSection;
    return (
      <Fragment>
        <Spacer />
        <Column>
          <Headline style={{ textAlign: "center", margin: 20 }}>
            {title}
          </Headline>
          <Spacer />
          <Divider />
          <Spacer />
          <Subheading style={{ margin: 20 }}>{introduction}</Subheading>
        </Column>
        <Spacer />
      </Fragment>
    );
  };

  if (isPending && !data) {
    return (
      <Container flex={1} center>
        <ActivityIndicator animating={true} size={50} />
      </Container>
    );
  }

  if (!data) return <View />;

  const { currentItem, subsections } = data;

  return (
    <MaxWidthView>
      <FlatList
        data={subsections}
        ListHeaderComponent={() => renderHeader(currentItem)}
        renderItem={renderItem}
        keyExtractor={(item) => `${item.id}`}
        refreshControl={
          <RefreshControl refreshing={isPending} onRefresh={execute} />
        }
      />
    </MaxWidthView>
  );
};
