import React from "react";
import { ScrollViewProps } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Surface } from "react-native-paper";

export const Wrapper: React.FC<ScrollViewProps> = (props) => {
  const { children, style, ...rest } = props;

  const defaultStyle = { flex: 1, elevation: 0 };

  return (
    <Surface style={{ flex: 1 }}>
      <ScrollView
        style={[defaultStyle, style]}
        showsVerticalScrollIndicator={false}
        {...rest}
      >
        {children}
      </ScrollView>
    </Surface>
  );
};
