import urlParser from "js-video-url-parser";
import { Platform } from "react-native";
import { API_URL } from "./env.json";
import { Company } from "./services/sdk";
import { BLACK, WHITE } from "./styles/colors";
import { getContrast } from "./styles/mixins";
import { ihbTheme } from "./styles/themes";

export const getCompanyLogo = (company: Company) => {
  const logoUrls = company.viewMeta?.logoUrl;
  const firstLogo = logoUrls && logoUrls[0];
  if (firstLogo?.url) {
    return API_URL + "/uploads/" + firstLogo.url;
  } else {
    const name = company.name;
    const color = getCompanyColors(company).primaryColor;
    const textColor = getContrast(color).replace(/^#/, "");
    const primaryColor = color.replace(/^#/, "");

    return `https://ui-avatars.com/api/?name=${name}&size=256&background=${primaryColor}&color=${textColor}`;
  }
};

export const getCompanyColors = (company: Company) => {
  const primaryColor = company.viewMeta?.primaryColor || BLACK;
  const secondaryColor = company.viewMeta?.secondaryColor || WHITE;
  return { primaryColor, secondaryColor };
};

export const getCompanyVideo = (company: Company) => {
  const url = company.viewMeta?.videoUrl;
  if (!url) return null;
  const info = urlParser.parse(url);

  if (info?.provider === "youtube") {
    return `https://www.youtube.com/embed/${info.id}?modestbranding=1`;
  }

  if (info?.provider === "vimeo") {
    return `https://player.vimeo.com/video/${info.id}`;
  }
  // If its not one of these providers
  // return empty url
  return null;
};

export const getCompanyTheme = (company: Company): ReactNativePaper.Theme => {
  const colors = getCompanyColors(company);

  return {
    ...ihbTheme,
    colors: {
      ...ihbTheme.colors,
      primary: colors.primaryColor,
      accent: colors.primaryColor,
      surface: colors.secondaryColor,
      text: getContrast(colors.secondaryColor),
    },
  };
};

const noGlow = `
textarea, select, input, button {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}
`;

export const injectWebCss = () => {
  // Only on web
  if (Platform.OS !== "web") return;

  // Inject style
  const style = document.createElement("style");
  style.textContent = `textarea, select, input, button { outline: none!important; }`;
  return document.head.append(style);
};
