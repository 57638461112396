// https://reactnavigation.org/docs/configuring-links#playground
import { LinkingOptions } from "@react-navigation/native";
import { APP_LINK } from "../env.json";

export const linking: LinkingOptions = {
  prefixes: [APP_LINK ?? "", "net.nomoremondays.inhandbook://"],
  config: {
    screens: {
      Home: {
        path: "",
        screens: {
          Home: "home",
          Inquiry: "inquiry/:inquiryTypeId",
        },
      },
      Handbook: {
        screens: {
          Handbook: "handbook",
          Section: "handbook/section/:sectionId",
          Subsection: "handbook/subsection/:subsectionId",
          Search: "handbook/search",
        },
      },
      Login: "login",
      ForgotPassword: "forgot-password",
      UpdatePassword: "reset-password/:token",
    },
  },
};
