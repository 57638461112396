import React from "react";
import { Text } from "react-native";
import { Container } from "../../rockts/ui-primitives";

type Props = {
  message: string;
};
export const EmptyState: React.FC<Props> = ({
  message = "Nothing to see here yet.",
}) => (
  <Container center flex={1}>
    <Text>{message}</Text>
  </Container>
);
