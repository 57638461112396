import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { useDimensions } from "../use-dimensions";

export const MaxWidthView: React.FC<{}> = (props) => {
  const { children } = props;
  const dimensions = useDimensions("window");
  const isDesktop = dimensions.width >= 768;

  const baseStyle: StyleProp<ViewStyle> = {
    maxWidth: "100%",
    alignItems: "center",
    flex: 1,
  };

  const containerStyle: StyleProp<ViewStyle> = {
    maxWidth: isDesktop ? 700 : "100%",
    width: "100%",
  };

  return (
    <View style={baseStyle}>
      <View style={containerStyle}>{children}</View>
    </View>
  );
};
