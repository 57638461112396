import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Platform } from "react-native";
import { API_URL } from "../env.json";
import { getRefreshToken, resetTokens, setTokens } from "./auth";
import { applyInterceptors } from "./auth/interceptors";
import { Tokens } from "./auth/types";
import {
  AckService,
  AuthCredentialsDto,
  AuthService,
  AuthUpdatePasswordDto,
  CompanyService,
  EnumSubscribeNotificationDtoDeviceType,
  HandbookService,
  InquiryTypeService,
  NotificationService,
  SectionService,
  SendInquiryDto,
  serviceOptions,
  SubsectionService,
  UserService,
} from "./sdk";

const PUSH_TOKEN_KEY = "PUSH_TOKEN";

class API {
  constructor() {
    this.init();
  }
  private init = () => {
    const http = axios.create({
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    serviceOptions.axios = http;
    applyInterceptors(http, {
      refreshToken: this.refreshToken,
      ignoreIfIncludes: "/auth/",
    });
  };

  signIn = async (params: AuthCredentialsDto): Promise<void> => {
    const response = await AuthService.authSignIn({ body: params });
    // Response DTO has optional the params needed
    await setTokens(response as Tokens);
  };

  logout = async (): Promise<void> => {
    await resetTokens();
    await this.notificationUnsubscribe();
  };

  getUser = async (id: string) => {
    return await UserService.userGetOne({ id });
  };

  refreshToken = async () => {
    const refreshToken = await getRefreshToken();
    if (!refreshToken) throw Error("No refresh token");

    const response = await AuthService.authRefresh({
      body: { refreshToken },
    });

    await setTokens(response as Tokens);
  };

  resetPassword = async (email: string) => {
    await AuthService.authResetPassword({ body: { email } });
  };

  updatePassword = async (token: string, params: AuthUpdatePasswordDto) => {
    await AuthService.authResetUpdatePassword({ token, body: params });
  };

  checkPasswordResetToken = async (token: string) => {
    await AuthService.authResetCheckToken({ token });
  };

  getCompany = async (companyId: string) => {
    return await CompanyService.companyGetOne({ id: companyId });
  };

  getInquiryTypes = async (companyId: string) => {
    const response = await CompanyService.companyGetManyInquiryTypes({
      companyId,
    });
    return response.data;
  };

  sendInquiry = async (params: SendInquiryDto) => {
    return InquiryTypeService.inquirySend({ body: params });
  };

  getHandbook = async (companyId: string) => {
    const response = await CompanyService.companyGetManyHandbooks({
      companyId,
    });
    // Only one handbook per company, get the first.
    return response.data && response.data[0];
  };

  getSections = async (handbookId: string) => {
    const response = await HandbookService.handbookGetManySections({
      handbookId,
    });
    return response.data;
  };

  getSubSections = async (sectionId: string) => {
    const response = await SectionService.sectionGetManySubsections({
      sectionId,
    });
    return response.data;
  };
  getSubSectionById = async (subSectionId: string) => {
    return await SubsectionService.subsectionGetOne({ id: subSectionId });
  };

  getSubsectionById = async (id: string) => {
    return await SubsectionService.subsectionGetOne({ id });
  };

  search = async (handbookId: string, query: string) => {
    return await HandbookService.handbookSearch({
      id: handbookId,
      webSearch: query,
    });
  };

  acknowledge = async (handbookId: string, userId: string) => {
    await AckService.ackCreateOne({
      body: {
        handbookId: handbookId,
        userId: userId,
      },
    });
  };

  notificationSubscribe = async (userId: string, token: string) => {
    // Running in simulator
    if (!token) return;

    // Save token to storage
    await AsyncStorage.setItem(PUSH_TOKEN_KEY, token);

    await NotificationService.notificationSubscribe({
      body: {
        fcmToken: token,
        deviceType: Platform.OS as EnumSubscribeNotificationDtoDeviceType,
        userId: userId,
      },
    });
  };

  notificationUnsubscribe = async () => {
    // Save token to storage
    const token = await AsyncStorage.getItem(PUSH_TOKEN_KEY);

    if (!token) return;

    await NotificationService.notificationUnsubscribe({
      body: {
        fcmToken: token,
      },
    });
  };
}

export const Client = new API();
