import Clipboard from "expo-clipboard";
import isEmpty from "lodash/isEmpty";
import React, { Fragment, useState } from "react";
import { Button, Divider, List, Text } from "react-native-paper";
import { BLACK } from "../../styles/colors";
import { Row, Spacer } from "../ui-primitives";
import DisplayData from "./jsonTree";

interface AccordionProps {
  data: any;
  icon: string;
  title: string;
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const { icon, title } = props;
  let { data } = props;
  if (!data) {
    data = {};
  }

  const [showData, setShowData] = useState(false);

  const toggleData = () => {
    setShowData(!showData);
  };

  return (
    <Fragment>
      <List.Accordion
        title={title}
        left={() => <List.Icon icon={icon} />}
        expanded={showData}
        onPress={toggleData}
      >
        <Row
          justifyContent="space-between"
          backgroundColor={BLACK}
          alignItems="center"
          paddingVertical={10}
        >
          {isEmpty(data) ? (
            <Text style={{ color: "#bbb" }}>This is empty.</Text>
          ) : (
            <Fragment>
              <Spacer />
              <Text style={{ color: "#fff" }}>
                Share this data to help troubleshoot.
              </Text>
              <Button
                icon="content-copy"
                mode="contained"
                color="black"
                style={{ marginLeft: 15 }}
                onPress={() => Clipboard.setString(data)}
              >
                Copy
              </Button>
            </Fragment>
          )}
        </Row>

        <DisplayData data={data} />
      </List.Accordion>
      <Divider />
    </Fragment>
  );
};

export default Accordion;
