import { Dimensions, PixelRatio } from "react-native";

const { width, height } = Dimensions.get("window");
export const VIEWPORT_WIDTH = width;
export const VIEWPORT_HEIGHT = height;

export const wp = (percentage: number) => {
  const value = (percentage * VIEWPORT_WIDTH) / 100;
  return Math.round(value);
};

export const hp = (percentage: number) => {
  const value = (percentage * VIEWPORT_HEIGHT) / 100;
  return Math.round(value);
};

const guidelineBaseWidth = 375;

export const scaleSize = (size: number) =>
  (VIEWPORT_WIDTH / guidelineBaseWidth) * size;

export const scaleFont = (size: number) => size * PixelRatio.getFontScale();

function dimensions(
  top: number,
  right = top,
  bottom = top,
  left = right,
  property: string
) {
  let styles = {} as { [key: string]: number };

  styles[`${property}Top`] = top;
  styles[`${property}Right`] = right;
  styles[`${property}Bottom`] = bottom;
  styles[`${property}Left`] = left;

  return styles;
}

export function margin(top: number, right = top, bottom = top, left = right) {
  return dimensions(top, right, bottom, left, "margin");
}

export function padding(top: number, right = top, bottom = top, left = right) {
  return dimensions(top, right, bottom, left, "padding");
}

export function boxShadow(
  color: string,
  offset = { height: 2, width: 2 },
  radius = 8,
  opacity = 0.4
) {
  return {
    shadowColor: color,
    shadowOffset: offset,
    shadowOpacity: opacity,
    shadowRadius: radius,
    elevation: radius,
  };
}

export function iconShadow() {
  return {
    shadowColor: "rgba(0,0,0, 0.4)",
    shadowOffset: { height: 4, width: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 4,
  };
}

export const getBrightness = (hexcolor?: string): "light" | "dark" => {
  if (!hexcolor) return "dark";
  const brightness = getContrast(hexcolor);
  if (brightness === "#000000") {
    return "dark";
  } else {
    return "light";
  }
};

export const getContrast = (hexcolor: string) => {
  if (!/^#[0-9A-F]{6}$/i.test(hexcolor)) {
    throw Error("Only accept hex color");
  }

  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function(hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "#000000" : "#FFFFFF";
};

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};
