import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";
import { SearchScreen } from "../../screens/search-screen";
import { Header } from "../components/Header";

const Stack = createStackNavigator();

export const SearchStack = () => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        header: (props) => <Header {...props} />,
      }}
    >
      <Stack.Screen
        name="Search"
        component={SearchScreen}
        options={{
          title: "Search",
          header: (_) => <View />,
        }}
      />
    </Stack.Navigator>
  );
};
