import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import { enableScreens } from "react-native-screens";
import AppContainer from "./src/AppContainer";
import { injectWebCss } from "./src/helpers";
import ModulesContainer from "./src/providers/ModulesContainer";
import { ihbTheme } from "./src/styles/themes";

injectWebCss();
enableScreens();

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   enableInExpoDevelopment: !__DEV__,
//   debug: __DEV__,
// });

function App() {
  return (
    <PaperProvider theme={ihbTheme}>
      <ModulesContainer>
        <AppContainer />
      </ModulesContainer>
    </PaperProvider>
  );
}

export default App;
