import React from "react";
import { Portal, Snackbar } from "react-native-paper";
import Toast from "react-native-toast-message";

type Props = {
  show: boolean;
  message: string;
  clear(): void;
};

export const SnackbarOverlay = (props: Props) => {
  const { show, clear, message } = props;

  return (
    <Portal>
      <Toast ref={(ref) => Toast.setRef(ref)} topOffset={40} />
      <Snackbar visible={show} duration={8000} onDismiss={() => clear()}>
        {message}
      </Snackbar>
    </Portal>
  );
};
