import React from "react";
import { StyleSheet } from "react-native";
import { ThemeProvider } from "react-native-paper";
import { Wrapper } from "../../components/atoms/Wrapper";
import { useStateProvider } from "../../providers/state.provider";
import { useNotificationProvider } from "../../services/notification/notification.provider";
import { standardTheme } from "../../styles/themes";
import { MaxWidthView } from "../ui-primitives/MaxWidthView";
import Accordion from "./accordion";

export const DebugScreen: React.FC<{}> = () => {
  const { user, company, handbook, inquiryTypes } = useStateProvider();
  const { expoPushToken } = useNotificationProvider();

  return (
    <ThemeProvider theme={standardTheme}>
      <Wrapper>
        <MaxWidthView>
          <Accordion title="User" icon="account" data={user} />
          <Accordion title="Company" icon="domain" data={company} />

          <Accordion title="Handbook" icon="book" data={handbook} />
          <Accordion title="Push Token" icon="bell" data={expoPushToken} />
          <Accordion
            title="Inquiry Types"
            icon="notebook"
            data={inquiryTypes}
          />
        </MaxWidthView>
      </Wrapper>
    </ThemeProvider>
  );
};

const styles = StyleSheet.create({
  textAreaContainer: {
    borderColor: "#ccc",
    borderWidth: 1,
    padding: 5,
    flex: 1,
  },
  textArea: {
    height: 150,

    justifyContent: "flex-start",
  },
});
