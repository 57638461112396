import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { DebugScreen } from "../../rockts/debugger/debug-screen";
import { HomeScreen } from "../../screens/home-screen";
import { InquiryScreen } from "../../screens/inquiry-screen";
import { Header } from "../components/Header";

const Stack = createStackNavigator();

export const HomeStack: React.FC<{}> = () => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        header: (props) => <Header {...props} />,
      }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "Home",
        }}
      />

      <Stack.Screen
        name="Inquiry"
        component={InquiryScreen}
        options={({ route }) => ({ title: route?.params?.title })}
      />
      <Stack.Screen name="Debugger" component={DebugScreen} />
    </Stack.Navigator>
  );
};
