import React, { FunctionComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

interface Props extends ViewStyle {
  center?: boolean;
}

export const Container: FunctionComponent<Props> = (props) => {
  const { children, center, ...rest } = props;

  const baseStyle: StyleProp<ViewStyle> = StyleSheet.flatten([
    center && {
      justifyContent: "center",
      alignItems: "center",
    },
    { ...rest },
  ]);

  return <View style={baseStyle}>{children}</View>;
};
